import React from "react"
import { graphql, Link } from "gatsby"
import { SectionTitle } from "../../components/styled"
import { FaAngleRight } from "react-icons/fa"
import { Seo } from "../../components/seo"
import Contact from "../../components/Contact"
import { languageData } from "../../translate/traduction"
const JobDetails = ({ data }) => {
  const dataJobs = data.allStrapiJobs.nodes
  const { Imposible, Negotiable, Posible } = dataJobs[0].NewGraduates
    ? dataJobs[0].NewGraduates
    : ""
  const { None, ThereRegulation, Yes } = dataJobs[0].VisaSupport
    ? dataJobs[0].VisaSupport
    : ""
  const noImage = require("../../images/icons/no-imagen.svg")
  const handleAddBrTag = string => {
    if (string === null) {
      return ""
    }
    return string.replace(/(\r\n|\n)/gm, "<br />")
  }

  return (
    <>
      <Seo
        title={`${data.allStrapiJobs.nodes[0].Title} | Ofertas de empleo/cambio de carrera/información de empleo en México [QUICK GLOBAL MEXICO]`}
        image={
          data.allStrapiJobs.nodes[0].types[0]
            ? data.allStrapiJobs.nodes[0].types[0].image.url
            : ""
        }
      />
      <section className="container jobs-listing">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-9" style={{ padding: 0 }}>
            <div className="jobs-listing__job-info jobs-listing__job-details">
              {dataJobs[0].Archived === true && (
                <>
                  <div style={{ marginTop: "1em" }}>
                    <SectionTitle>
                      Las solicitudes para este trabajo han sido cerradas.
                    </SectionTitle>
                    <hr className="u-line-bottom" />
                    <p className="u-margin-bottom-1 u-margin-top-1">
                      Información de trabajo,
                      <Link to="/es/jobs-listing" className="u-color-danger">
                        lista de trabajos
                      </Link>
                      ingrese acá.
                    </p>
                  </div>
                  <hr className="u-line-bottom" />
                  <div className="job-close-text">
                    <p>
                      <b>
                        Si quiere hablar sobre los requerimientos,
                        <br />
                        Si se registra a continuación, un miembro del personal
                        le presentará las ofertas de trabajo.
                        <br />
                      </b>
                      ※ Para aquellos que ya han tenido una entrevista previa,
                      por favor contacten a su entrevistador.
                    </p>
                    <div className="btns-job-close">
                      <Link
                        to="/es/job-form"
                        state={{ workid: dataJobs[0].Manualid }}
                      >
                        Formulario para consulta GRATUITA
                      </Link>
                    </div>
                  </div>
                </>
              )}

              <hr className="u-line-bottom" />
              <>
                {dataJobs.map(jobs => (
                  <>
                    <div className="container-full jobs-listing__job-info--box-job">
                      <div
                        className="row spanish-title"
                        style={{ alignItems: "center" }}
                      >
                        <div className="jobs-listing__job-info--image  col-4 col-sm-3">
                          <div className="box-nro-item">
                            {jobs.types[0] ? (
                              <img src={jobs.types[0].image.url} alt="" />
                            ) : (
                              <img className="no-imagen" src={noImage} alt="" />
                            )}
                            <span>ID {jobs.Manualid}</span>
                          </div>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--tags">
                          <h1 className="u-display-text">
                            <Link to={`/es/jobs-listing/${jobs.Manualid}`}>
                              {jobs.Title}
                            </Link>
                          </h1>
                          <ul>
                            {jobs.marks && (
                              <>
                                {jobs.marks.map((marker, i) => (
                                  <li key={i}>
                                    {/* className="active-tag" */}
                                    <Link to={`/es/job-search/${marker.name}`}>
                                      {
                                        languageData["es"].modal.conditions[
                                          marker.name
                                        ]
                                      }
                                    </Link>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Puesto</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          {jobs.types && (
                            <>
                              {jobs.types.map((type, i) => (
                                <span key={i}>
                                  <Link to={`/es/jobs-types/${type.name}`}>
                                    {languageData["es"].modal.jobs[type.name]}
                                  </Link>
                                </span>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Industria</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                          <span>
                            <Link to={`/es/jobs-listing/${jobs.JobCategorie}`}>
                              {jobs.JobCategorie}
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Lugar de trabajo</span>
                        </div>
                        <div className="col-8 col-sm-9 jobs-listing__job-info--description jobs-listing__job-info--description--display">
                          {jobs.locations && (
                            <>
                              {jobs.locations.map((location, i) => (
                                <>
                                  {location.name !== "その他" &&
                                  location.name !== "日本" ? (
                                    <>
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/es/jobs-location/${location.name}`}
                                        >
                                          {
                                            languageData["es"].modal
                                              .locationsSub[location.name]
                                          }
                                        </Link>
                                      </span>

                                      {i === jobs.locations.length - 1 && (
                                        <span style={{ marginRight: "5px" }}>
                                          - México
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        key={i}
                                        style={{ marginRight: "5px" }}
                                      >
                                        <Link
                                          to={`/es/jobs-location/${location.name}`}
                                        >
                                          {location.name}
                                        </Link>
                                      </span>
                                    </>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                          <span>{jobs.LocationDescription}</span>
                        </div>
                      </div>
                      {jobs.JobDescription && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Funciones principales del puesto</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.JobDescription),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                          <span>Sueldo Mensual (Bruto)</span>
                        </div>
                        <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobs.Salary,
                            }}
                          ></span>
                        </div>

                        {jobs.SalaryIncrease && (
                          <>
                            <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                              <span>Aumento de salario/bonificación</span>
                            </div>
                            <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: handleAddBrTag(jobs.SalaryIncrease),
                                }}
                              ></span>
                            </div>
                          </>
                        )}
                      </div>

                      {jobs.WorkingTime && (
                        <>
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Horario</span>
                          </div>
                          <div className="col-md-3 col-sm-3 col-8 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.WorkingTime),
                              }}
                            ></span>
                          </div>
                        </>
                      )}
                      {jobs.HolidayVacation && (
                        <>
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title jobs-listing__job-info--title--width">
                            <span>Feriados / Vacaciones </span>
                          </div>
                          <div className="col-4 col-sm-3 col-8 jobs-listing__job-info--description jobs-listing__job-info--description--width">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: jobs.HolidayVacation,
                              }}
                            ></span>
                          </div>
                        </>
                      )}
                      {jobs.Insurance && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Seguro social/Seguro opcional</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.Insurance),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      {jobs.Allowances && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Prestaciones adicionales</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.Allowances),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      {jobs.MandatoryRequirement && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Requisitos indispensables</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(
                                  jobs.MandatoryRequirement
                                ),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}

                      {jobs.WelcomeRequirement && (
                        <div className="row">
                          <div className="col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Requisitos deseables</span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: handleAddBrTag(jobs.WelcomeRequirement),
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {jobs.CompanyProfile && (
                        <div className="row">
                          <div className=" col-4 col-sm-3 jobs-listing__job-info--title">
                            <span>Descripción de la empresa </span>
                          </div>
                          <div className="col-8 col-sm-9 jobs-listing__job-info--description">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: jobs.CompanyProfile,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {jobs.NewGraduates && jobs.VisaSupport && (
                        <div className="row jobs-listing__buttons-description">
                          {Posible && Imposible && Negotiable ? (
                            <p
                              className={`jobs-listing__buttons-description--${
                                (Posible && !Imposible && !Negotiable) ||
                                (!Posible && !Imposible && Negotiable)
                                  ? "positivo"
                                  : "negativo" ||
                                    (!Posible && Imposible && !Negotiable)
                                  ? "negativo"
                                  : ""
                              }`}
                            >
                              Solicitud de ingreso para nuevos estudiantes de
                              posgrado
                              <span>
                                {Posible && !Imposible && !Negotiable
                                  ? "si"
                                  : "" || (!Posible && !Imposible && Negotiable)
                                  ? "negociable"
                                  : "" || (!Posible && Imposible && !Negotiable)
                                  ? "no"
                                  : ""}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}
                          {Yes && ThereRegulation && None ? (
                            <p
                              className={`jobs-listing__buttons-description--${
                                (Yes && !ThereRegulation && !None) ||
                                (!Yes && ThereRegulation && !None)
                                  ? "positivo"
                                  : "negativo" ||
                                    (!Yes && !ThereRegulation && None)
                                  ? "negativo"
                                  : ""
                              }`}
                            >
                              Ayuda para la adquisición de visado{" "}
                              <span>
                                {Yes && !ThereRegulation && !None
                                  ? "can be"
                                  : "" || (!Yes && ThereRegulation && !None)
                                  ? "regulations apply"
                                  : "" || (!Yes && !ThereRegulation && None)
                                  ? "none"
                                  : ""}
                              </span>
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      <div className="row jobs-listing__text-description">
                        <b>
                          Los detalles se proporcionarán durante la primera
                          entrevista. Si desea registrarse, haga clic a
                          continuación.
                        </b>
                        <br></br>
                        <span className="jobs-listing__text-description--font">
                          ※ Para aquellos que ya han tenido una entrevista
                          previa, por favor contacten a su entrevistador.
                        </span>
                      </div>
                      <div className="row jobs-listing__links">
                        <Link
                          to="/es/job-form"
                          state={{ workid: dataJobs[0].Manualid }}
                        >
                          <FaAngleRight />
                          Consulta GRATUITA
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </div>

            <Contact lang={"es"} />
          </div>
        </div>
      </section>
    </>
  )
}

export default JobDetails

export const query = graphql`
  query JobDetailsQueryEs($slug: String!) {
    allStrapiJobs(filter: { Manualid: { eq: $slug } }) {
      nodes {
        Allowances
        Archived
        CompanyProfile
        DatePublication
        HolidayVacation
        Insurance
        JobDescription
        Manualid
        Salary
        SalaryIncrease
        MandatoryRequirement
        WelcomeRequirement
        Title
        WorkingTime
        JobCategorie
        LocationDescription
        NewGraduates {
          Imposible
          Negotiable
          Posible
        }
        locations {
          name
        }
        marks {
          name
        }
        types {
          name
          image {
            url
          }
        }
        VisaSupport {
          None
          ThereRegulation
          Yes
        }
        Archived
        DatePublication
      }
    }
  }
`
